import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isIOS, isMobile } from 'react-device-detect';
import { Link, withRouter } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Hashids from 'hashids/cjs';
import { connect } from 'react-redux';
import Avatar from '@material-ui/core/Avatar';
import { saveItem } from '../../pages/SavedItems/helper';
import Placeholder from '../../../config/placeholder.config';
import ActionSheet from '../ActionSheet';
import Icon from '../Icon';
import ShareItem from '../../models/ShareItem';
import ShareIntent from '../../utils/shareUtils';
import ImageGrid from '../../components/image-grid';
import VideoSource from '../VideoSource';
import { config } from '../../settings';
import {
  dayFromTimestamp,
  monthFromTimestamp,
  renderEventTimestamp,
  renderTimestamp,
  timeFromTimestamp,
} from '../../utils/formatting';
import FeedCardActions from '../FeedCardActions';
import { getProfileImage } from '../FeedBusinessPostCardList';
import { redirectAuth } from '../../utils/redirect';

const hashids = new Hashids('', 12);

function FeedEventCard({ event, auth, compact, history, showTitle, timestamp, onCommentClick }) {
  const host = event.venue || event.brand;
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  // FIXME These should be dynamic - for example, a user should be able to edit or delete their own event, and reporting themselves probably will never happen ha
  const moreOptions = [{
    content: 'Save this event',
    icon: 'bookmark',
    value: 'save',
    onClick: () => {
      if (auth && auth.user) {
        saveItem(auth.user, 'event', event);
      } else {
        // TODO Can we trigger the save when it returns?
        redirectAuth(`${config.authEndPoint}/login?path=${encodeURIComponent(`${window.location.href}?action=save&itemId=${event.id}`)}`);
      }
    },
  }, {
    content: 'Share',
    icon: 'share',
    value: 'share',
    onClick: () => {
      ShareIntent.share(new ShareItem({
        title: event.name,
        path: 'event',
        route: `/events/${hashids.encode(event.id)}`,
        image: event.image_url,
      }));
    },
  }, {
    content: 'Report Post',
    value: 'report',
    onClick: () => {
      console.log('TODO Send email to our admins with the details?');
    },
  }];

  const getImage = () => {
    let imageUrl = Placeholder.cigar;

    if (event.image_url) {
      imageUrl = event.image_url;
    } else if (event.image_url) {
      imageUrl = event.image_url;
    }

    return imageUrl;
  };

  // const toggleSavedItem = (save) => {
  //   const item = JSON.parse(JSON.stringify(event));
  //   const { user } = auth;
  //
  //   console.log('Toggling saved product for state:');
  //   if (save) {
  //     console.log('save');
  //     saveItem(user, 'event', item);
  //   } else {
  //     console.log('unsave');
  //     unsaveItem(user, {
  //       item_id: item.id,
  //       type: 'event',
  //     });
  //   }
  // };

  const toggleMoreOptions = () => setShowMoreOptions(!showMoreOptions);

  const renderMoreOptionsSheet = () => {
    if (isIOS) {
      return (
        <div>
          <ActionSheet
            show={showMoreOptions}
            menus={moreOptions}
            onRequestClose={toggleMoreOptions}
            cancelText="Cancel"
          />
        </div>
      );
    }
    return (
      <div>
        <Drawer
          anchor="bottom"
          open={showMoreOptions}
          onClick={toggleMoreOptions}
          onRequestClose={toggleMoreOptions}
        >
          <div
            role="presentation"
            onClick={toggleMoreOptions}
            onKeyDown={toggleMoreOptions}
          >
            <List>
              {/* FIXME Render a cancel button? */}
              { moreOptions.map((item) => (
                <ListItem onClick={item.onClick}>
                  <ListItemText primary={item.content} />
                </ListItem>
              ))}
            </List>
          </div>
        </Drawer>
      </div>
    );
  };

  // FIXME Who posted the event?
  const renderHeader = () => {
    if (compact) {
      return (
        <div style={{ display: 'flex', padding: 5 }} className="feed-event-card-header">
          <Link to={`/events/${hashids.encode(event.id)}`}>
            <Avatar
              src={host && (getProfileImage(host.profile_image_url) || getProfileImage(host.logo_image_url))}
              alt={host && host.name}
              style={{ height: 28, width: 28, margin: '8px 12px' }}
            >
              {(host && host.name.charAt(0)) || 'E'}
            </Avatar>
          </Link>
          <div style={{ flex: 1 }}>
            {/* FIXME These should be using the "host" not the location - it can be a brand or a venue (any business profile) */}
            {host && <div style={{ fontWeight: 600, color: '#2b2b2b !important' }}>{host.name}</div>}
            {!host && <div style={{ fontWeight: 600, color: '#2b2b2b !important' }}>{event.is_online ? 'Online Event' : 'Local Event'}</div>}
            <div style={{ fontSize: 12 }}>{renderTimestamp(timestamp)}</div>
          </div>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="More Options"
            style={{ marginRight: 5 }}
            onClick={toggleMoreOptions}
          >
            <Icon name="more-horizontal" style={{ height: 22, width: 22 }} />
          </IconButton>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', padding: 5 }} className="feed-event-card-header">
        <Link to={`/events/${hashids.encode(event.id)}`}>
          <Avatar
            src={event.image_url}
            alt={event.is_online ? 'Online Cigar Event on Boxpressd' : 'Local Cigar Event on Boxpressd'}
            style={{ height: 28, width: 28, margin: '8px 12px' }}
          >
            {event.is_online ? <Icon name="globe" /> : <Icon name="map" />}
          </Avatar>
        </Link>
        <div style={{ flex: 1 }}>
          <div style={{ fontWeight: 600, color: '#2b2b2b !important' }}>{event.is_online ? 'Online Event' : 'Local Event'}</div>
          <div style={{ fontSize: 12 }}>{renderEventTimestamp(event.start_timestamp)}</div>
        </div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="More Options"
          style={{ marginRight: 5 }}
          onClick={toggleMoreOptions}
        >
          <Icon name="more-horizontal" style={{ height: 22, width: 22 }} />
        </IconButton>
      </div>
    );
  };

  const renderMedia = () => (
    <div className="image">
      { renderHeader() }
      { renderInnerMedia() }
    </div>
  );

  const renderInnerMedia = () => {
    if (event.media && event.media.length > 0) {
      if (event.media[0].media_type === 'video') {
        return (
          <VideoSource
            videoSrc={event.media[0].media_url}
            width="350"
            height="233"
            placeholder={event.media[0].thumbnail_url}
          />
        );
      }
      return (
        <ImageGrid
          images={event.media.map((media) => media.media_url)}
          thumbnailImages={event.media.map((media) => media.media_url.replace('/event/', '/event/500x500/'))}
          onClick={() => history.push(`/events/${hashids.encode(event.id)}`)}
        />
      );
    }
    // if (event.video_url) {
    //   return (
    //     <VideoSource
    //       videoSrc={event.video_url}
    //       width="350"
    //       height="233"
    //       // placeholder={event.video_url} // FIXME First frame from video
    //       // title={post.title}
    //     />
    //   );
    // }
    return (
      <Link to={`/events/${hashids.encode(event.id)}`}>
        <div className="img" style={{ backgroundImage: `url("${getImage()}"), url("${Placeholder.cigar}")` }} />
      </Link>
    );
  };

  const hasMedia = event.image_url || event.video_url || event.image_url || (event.media && event.media.length > 0);
  return (
    <div className="feed-card-item">
      { (hasMedia) && renderMedia() }
      <div className="content">
        { !hasMedia && renderHeader() }
        <div className="content-right" style={{ width: '100%', minHeight: isMobile ? 285 : 230, paddingTop: 1 }}>
          {!event.start_timestamp && showTitle && <Link to={`/events/${hashids.encode(event.id)}`}><h3>{event.name}</h3></Link>}
          {event.start_timestamp && showTitle && (
            <Link to={`/events/${hashids.encode(event.id)}`}>
              <div style={{ display: 'flex', marginBottom: 20 }}>
                <div style={{ marginTop: 6, borderRadius: 8, overflow: 'hidden', backgroundColor: '#efefef', color: '#656565', height: 56, width: 56, boxShadow: '0 2px 12px 1px hsla(0,0%,67.8%,.2)', marginRight: 10 }}>
                  <div style={{ backgroundColor: '#f3425f', height: 16, color: '#ffffff', textAlign: 'center', lineHeight: '16px' }}>{monthFromTimestamp(event.start_timestamp).toUpperCase()}</div>
                  <div style={{ justifyContent: 'center', fontWeight: 600, fontSize: 24, width: '100%', lineHeight: '38px', textAlign: 'center' }}>{dayFromTimestamp(event.start_timestamp)}</div>
                </div>
                <div style={{ flex: 1 }}>
                  <h4 style={{ marginTop: 6, marginBottom: 6 }}>{event.name}</h4>
                  {event.start_timestamp && <div>{`${timeFromTimestamp(event.start_timestamp)}${event.end_timestamp ? (` - ${timeFromTimestamp(event.end_timestamp)}`) : ''}`}</div>}
                </div>
              </div>
            </Link>
          )}
          <Link to={`/events/${hashids.encode(event.id)}`}><span>{event.description}</span></Link>
          {event.venue && (
            <Link to={`/venues/${hashids.encode(event.venue.id)}`}>
              {event.venue && (
                <div style={{ marginBottom: 20 }}>
                  <Icon name="map-pin" />
                  {' '}
                  {event.venue.name}
                </div>
              )}
            </Link>
          )}
        </div>
        <FeedCardActions
          postId={event.id}
          // commentType="event"
          // commentRoute="events"
          // comments={comments}
          // commentOnClick={compact ? () => {
          //   // FIXME Just pass it no matter what?
          //   if (typeof onCommentClick === 'function') {
          //     onCommentClick();
          //   }
          // } : null}
          onLikedToggle={(liked) => {
            console.log(`The post is ${liked ? 'liked' : 'not liked'}`);
          }}
          // onPostComment={(comment, parentId) => {
          //   console.log('Comment posted...');
          //   console.log(comment);
          //   axios.post(`${config.apiEndPoint}/events/${event.id}/comments`, {
          //     event_id: event.id,
          //     // FIXME We don't want any HTML past this point - convert to plain text - use something better than this approach?
          //     comment: comment.replace('<br>', ''),
          //     user_id: auth.user.id,
          //     parent_id: parentId,
          //     comment_timestamp: new Date().toISOString(),
          //   });
          // }}
          onShare={() => ShareIntent.share(new ShareItem({
            title: event.name,
            text: event.description, // FIXME Something more pertinent to our app instead? Like "View event details on Boxpressd"
            path: 'events',
            route: `/events/${hashids.encode(event.id)}`,
            image: getProfileImage(host.profile_image_url) || getProfileImage(host.logo_image_url),
          }))}
        />
      </div>

      {/* FIXME Does this kind of thing require lazy loading cards? That's a lot of extra DOM elements loaded when it only really needs to render when it's about to be shown */}
      { showMoreOptions && renderMoreOptionsSheet() }
    </div>
  );
}

FeedEventCard.propTypes = {
  event: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

export default connect(mapStateToProps)(withRouter(FeedEventCard));
