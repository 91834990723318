import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ContentEditable from 'react-contenteditable';
import { isMobile, isIOS } from 'react-device-detect';
import Avatar from '@material-ui/core/Avatar';
import Hashids from 'hashids/cjs';
import Icon from '../Icon';
import PostActionIcons from '../PostActionIcons';
import { ReactComponent as ThumbsUp } from '../../../common-assets/images/thumbs-up.svg';
import { ReactComponent as ThumbsUpOutline } from '../../../common-assets/images/thumbs-up-outline.svg';
import { BusinessStore, CigarStore, GroupStore, SettingsStore, ShopStore, VenueStore } from '../../stores';
import { likeItem, unlikeItem } from '../../utils/actions/likes.actions';
import { config } from '../../settings';
import { language, messages } from '../../utils/localeUtils';
import CommentList from '../../pages/Comments/components/commentlist';
import { redirectAuth } from '../../utils/redirect';
const hashids = new Hashids('', 12);

function FeedCardActions(props) {
  // console.log('Liked Sessions');
  // console.log(likedSessions);
  const [liked, setLiked] = useState(false);
  const [attachment, setAttachment] = useState(null);
  const [likesCount, setLikesCount] = useState(0);
  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const { auth, showComments } = props;

  const textContent = useRef(null);

  useEffect(() => {
    if (props.commentType === 'session') {
      const likedSessions = CigarStore.getRawState().sessions.liked;
      setLiked(likedSessions.indexOf(props.postId) !== -1);
    } else if (props.commentType === 'checkin') {
      const likedCheckins = VenueStore.getRawState().checkins.liked;
      setLiked(likedCheckins.indexOf(props.postId) !== -1);
    } else if (props.commentType === 'product_review') {
      const likedReviews = ShopStore.getRawState().products.reviews.liked;
      setLiked(likedReviews.indexOf(props.postId) !== -1);
    } else if (props.commentType === 'business_post') {
      const likedReviews = BusinessStore.getRawState().posts.liked;
      setLiked(likedReviews.indexOf(props.postId) !== -1);
    } else if (props.commentType === 'podcast_episode') {
      const likedReviews = BusinessStore.getRawState().podcasts.episodes.liked;
      setLiked(likedReviews.indexOf(props.postId) !== -1);
    } else if (props.commentType === 'blog_article') {
      const likedReviews = BusinessStore.getRawState().blogs.articles.liked;
      setLiked(likedReviews.indexOf(props.postId) !== -1);
    } else if (props.commentType === 'event') {
      const likedReviews = BusinessStore.getRawState().events.liked;
      setLiked(likedReviews.indexOf(props.postId) !== -1);
    } else if (props.commentType === 'group_post') {
      const likedReviews = GroupStore.getRawState().posts.liked;
      setLiked(likedReviews.indexOf(props.postId) !== -1);
    }

    let commentsEndpoint;
    const decodedId = props.postId;
    if (props.commentType === 'session') {
      commentsEndpoint = `${config.apiEndPoint}/sessions/${decodedId}/comments`;
    } else if (props.commentType === 'checkin') {
      commentsEndpoint = `${config.apiEndPoint}/venues/checkins/${decodedId}/comments`;
    } else if (props.commentType === 'product_review') {
      commentsEndpoint = `${config.shopEndPoint}/products/reviews/${decodedId}/comments`;
    } else if (props.commentType === 'business_post') {
      commentsEndpoint = `${config.apiEndPoint}/businesses/posts/${decodedId}/comments`;
    } else if (props.commentType === 'podcast_episode') {
      commentsEndpoint = `${config.apiEndPoint}/businesses/podcasts/episodes/${decodedId}/comments`;
    } else if (props.commentType === 'blog_article') {
      commentsEndpoint = `${config.apiEndPoint}/businesses/blogs/articles/${decodedId}/comments`;
    } else if (props.commentType === 'event') {
      commentsEndpoint = `${config.apiEndPoint}/events/${decodedId}/comments`;
    } else if (props.commentType === 'groups_post') {
      commentsEndpoint = `${config.apiEndPoint}/groups/posts/${decodedId}/comments`;
    } // TODO Other types

    if (commentsEndpoint) {
      axios.get(commentsEndpoint).then((response) => {
        console.log('Got post comments:');
        console.log(response.data);
        setComments(response.data);
        // setLoading(false);
        if (!commentCount) {
          setCommentCount(response.data.length);
        }
      }).catch((err) => {
        console.error(err);
        // setLoading(false);
      });
    } else {
      // 404? Error? Sentry notice?
    }
  }, []);

  useEffect(() => {
    setCommentCount(props.commentCount);
  }, [props.commentCount]);

  useEffect(() => {
    setLikesCount(props.likeCount);
  }, [props.likeCount]);

  const toggleLiked = () => {
    if (auth && auth.user) {
      if (liked) {
        unlikeItem(auth.user, {
          item_id: props.postId,
          type: props.commentType,
        });
      } else {
        likeItem(auth.user, {
          item_id: props.postId,
          type: props.commentType,
        });
      }
      setLiked(!liked);
      if (typeof props.onLikedToggle === 'function') {
        props.onLikedToggle(!liked);
      }
    } else {
      // TODO Attach some query params to let us know they tried to like an item so we can toggle it after they log in
      redirectAuth();
    }
  };

  const share = () => {
    // TODO Show users to share with? For now, just show the typical share menu
    if (props.onShare) {
      props.onShare();
    }
  };

  const submitComment = (content) => {
    if (typeof props.onPostComment === 'function') {
      // TODO Sanitize text before passing to onPostComment
      props.onPostComment(textContent.current, selectedMessage ? selectedMessage.id : null); // TODO Include the attached media here?
    }
    textContent.current = null;
    setCommentCount(commentCount + 1);
    // TODO Post it to the comment list
  };

  const { darkMode } = SettingsStore.getRawState();

  return (
    <div>
      {(likesCount > 0 || commentCount > 0) && (
        <div
          style={{ display: 'flex', padding: '12px 20px' }}
          onClick={() => {
            if (auth && auth.user) {
              if (isMobile) {
                props.history.push(`/${props.commentRoute}/${hashids.encode(props.postId)}/comments`);
              } else {
                // TODO What? Just expand them inline with load more button?
              }
            } else {
              const endpoint = `${window.location.protocol}//${window.location.host}`;
              const redirectUrl = `${endpoint}/${props.commentRoute}/${hashids.encode(props.postId)}/comments`;
              redirectAuth(redirectUrl);
            }
          }}
        >
          {likesCount > 0 && <span style={{ flex: 1 }}>{`${likesCount} Likes`}</span>}
          {commentCount > 0 && <span style={{ flex: 1, textAlign: 'right' }} className="link">{`${commentCount} ${messages[language]?.comments || 'Comments'}`}</span>}
        </div>
      )}
      <div
        id="post-actions"
        style={{
          display: 'flex',
          borderTop: darkMode ? '1px solid #3c424c' : '1px solid rgb(210, 210, 210, 0.5)',
          borderBottom: isMobile ? 'none' : (darkMode ? '1px solid #3c424c' : '1px solid rgb(210, 210, 210, 0.5)'),
        }}
      >
        <div style={{ flex: 1, textAlign: 'center', padding: 10, cursor: 'pointer' }} onClick={toggleLiked}>
          <Icon vendor="svg" component={liked ? ThumbsUp : ThumbsUpOutline} />
          <span style={{ marginLeft: 8, display: 'inline-block', /*width: 40*/ }}>{liked ? messages[language]?.liked || 'Liked' : messages[language]?.like || 'Like'}</span>
        </div>
        {props.onPostComment && (
          <div
            style={{ flex: 1, textAlign: 'center', padding: 10, cursor: 'pointer' }}
            onClick={() => {
              if (auth && auth.user) {
                if (typeof props.commentOnClick === 'function') {
                  props.commentOnClick();
                } else if (isMobile) {
                  props.history.push(`/${props.commentRoute}/${hashids.encode(props.postId)}/comments`);
                } else {
                  jQuery(`#text-content-${props.postId}`).focus();
                }
              } else {
                const endpoint = `${window.location.protocol}//${window.location.host}`;
                const redirectUrl = `${endpoint}/${props.commentRoute}/${hashids.encode(props.postId)}/comments`;
                redirectAuth(redirectUrl);
              }
            }}
          >
            <Icon name="message-circle" />
            <span style={{ marginLeft: 8 }}>{messages[language]?.comment || 'Comment'}</span>
          </div>
        )}
        {/* FIXME If share link generation in progress, show loading spinner instead of share icon - see Hevy app */}
        {props.onShare && (
          <div style={{ flex: 1, textAlign: 'center', padding: 10, cursor: 'pointer' }} onClick={share}>
            {isIOS ? <Icon name="share" /> : <Icon name="share2" />}
            <span style={{ marginLeft: 8, display: 'inline-block', /*width: 40*/ }}>{messages[language]?.share || 'Share'}</span>
          </div>
        )}
      </div>
      {(!isMobile || showComments) && (
        <div className="desktop-comment-container">
          <CommentList
            comments={comments}
            // isTyping={isTyping} // Boolean: is the recipient typing
            onReply={(message) => {
              if (isMobile) {
                if (auth && auth.user) {
                  // TODO Pass the message into the Comments component to pre-fill the input
                  props.history.push(`/${props.commentRoute}/${hashids.encode(props.postId)}/comments`);
                } else {
                  const endpoint = `${window.location.protocol}//${window.location.host}`;
                  const redirectUrl = `${endpoint}/${props.commentRoute}/${hashids.encode(props.postId)}/comments`;
                  redirectAuth(redirectUrl);
                }
              } else {
                setSelectedMessage(message);
                // FIXME This is good to display it but it needs to post to the server as `[@${message.user.full_name}|userId:${message.user.id}] ${textContent || ''}`
                textContent.current = `<a href="#">${message.user.full_name}</a>&nbsp;${textContent.current || ''}`;
                jQuery(`#text-content-${props.postId}`).focus();
              }
            }}
          />
        </div>
      )}
      {!isMobile && auth && auth.user && (
        <div
          style={{
            display: 'flex',
            borderTop: darkMode ? '1px solid #3c424c' : '1px solid rgb(210, 210, 210), 0.5',
            alignItems: 'center',
          }}
        >
          <Avatar src={auth.user && auth.user.image_url} style={{ height: 28, width: 28, margin: '8px 12px' }}>{auth.user && auth.user.first_name.charAt(0)}</Avatar>
          <ContentEditable
            id={`text-content-${props.postId}`}
            style={{ borderRadius: 20, marginRight: 8 }}
            className="contenteditable form-control form-input form-control-contrast"
            tagName="div"
            html={textContent.current}
            placeholder="Write a comment..."
            onChange={(e) => {
              textContent.current = e.target.value;
            }}
            // onBlur={sanitize}
            onKeyUp={(e) => {
              if (!isMobile && e.key === 'Enter') {
                e.preventDefault();
                submitComment();
              }
            }}
          />
          <div style={{ marginRight: 8, width: 185 }}>
            <PostActionIcons
              uid={props.postId}
              // type="popover"
              gifPickerWidth={280}
              gifPickerColumns={1}
              emojiPickerWidth={350}
              onGifSelected={(item) => setAttachment(item.embed_url)}
              onEmojiSelected={(data) => {
                if (data.native) {
                  textContent.current = `${textContent || ''} ${data.native}`;
                } else if (data.imageUrl) {
                  textContent.current = `${textContent || ''} <img src="${data.imageUrl}" class="emoji" />`;
                  // TODO How can we set an image in the text field? This is the only way to handle the custom emojis
                  //  Slack does contenteditable="true" div and then an image in a <p> <img /> some text <img /></p>
                  /*
                  <img data-id=":boxpressd-like:" data-title=":boxpressd-like:" data-stringify-text=":boxpressd-like:" class="emoji" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" alt=":boxpressd-like:" style="background-image: url(https://emoji.slack-edge.com/TN8BSDVHR/boxpressd-like/e86a64aefa8ef638.png);">
                   */
                }
              }}
              onMediaSelected={(e) => {
                // TODO Attach to state and upload to server
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

FeedCardActions.propTypes = {
  commentCount: PropTypes.number,
  likeCount: PropTypes.number,
};

FeedCardActions.defaultProps = {
  commentCount: 0,
  likeCount: 0,
};

const mapStateToProps = (state) => ({ auth: state.get('auth').toJS() });

export default connect(mapStateToProps)(withRouter(FeedCardActions));
