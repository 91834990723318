import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import Rating from 'react-rating';
import { Col, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Hashids from 'hashids/cjs';
import { connect } from 'react-redux';
import { saveItem, unsaveItem } from '../../pages/SavedItems/helper';
import Placeholder from '../../../config/placeholder.config';
import { deleteCheckin as deleteCheckinAction } from '../../pages/CheckinEditor/actions';
import Icon from '../Icon';
import ShareItem from '../../models/ShareItem';
import ShareIntent from '../../utils/shareUtils';
import MediaGrid from '../../components/media-grid';
import VideoSource from '../VideoSource';
import { renderTimestamp } from '../../utils/formatting';
import ModalDialog from '../ModalDialog';
import { config } from '../../settings';
import BottomSheet from '../bottom-sheet';
import FeedCardActions from '../FeedCardActions';
import { VenueStore } from '../../stores';
import { language, messages } from '../../utils/localeUtils';
import Venue from '../../models/Venue';
import { Resize } from '../../utils/imageUtils';
import axios from 'axios';
import { redirectAuth } from '../../utils/redirect';

const hashids = new Hashids('', 12);

export function FeedCheckinCard(props) {
  const itemSaved = VenueStore.useState((s) => s.saved.indexOf(parseInt(props.checkin.venue.id)) !== -1);

  const checkinImage = () => {
    const { checkin } = props;
    if (checkin.media && checkin.media.length) {
      for (let i = 0; i < checkin.media.length; i++) {
        const m = checkin.media[i];
        if (m.media_type === 'image') {
          return Resize.size(m.media_url);
        }
      }
    }
    return Venue.getImage(props.checkin.venue);
  };

  const [moreOptions, setMoreOptions] = useState([{
    content: itemSaved ? 'Unsave this venue' : 'Save this venue',
    icon: 'bookmark',
    value: 'save',
    onClick: () => {
      if (props.auth && props.auth.user) {
        if (itemSaved) {
          console.log('save');
          saveItem(props.auth.user, 'venue', props.checkin.venue);
        } else {
          console.log('unsave');
          unsaveItem(props.auth.user, {
            item_id: props.checkin.venue.id,
            type: 'venue',
          });
        }
      } else {
        // TODO Can we trigger the save when it returns?
        redirectAuth(`${window.location.href}?action=save&itemId=${props.checkin.venue.id}`);
      }
    },
  }, {
    content: 'Share',
    icon: 'share',
    value: 'share',
    onClick: () => {
      const { venue } = props.checkin;
      ShareIntent.share(new ShareItem({
        title: venue.name,
        text: props.checkin.comment,
        path: 'venue',
        route: `/venues/${hashids.encode(venue.id)}`,
        image: checkinImage(),
      }));
    },
  }, {
    content: 'Embed',
    icon: 'code',
    value: 'embed',
    onClick: () => {
      window.open(`${config.embedEndPoint}?query=${config.appUrl}/venues/${hashids.encode(props.checkin.venue_id)}?checkinId=${hashids.encode(props.checkin.id)}`, '_blank');
    },
  }]);

  useEffect(() => {
    console.log('Checking details:');
    console.log(props.checkin);
    // FIXME Can this be simplified?
    if ((props.checkin && parseInt(props.checkin.user_id)) === (props.auth && props.auth.user && parseInt(props.auth.user.id))) {
      console.log('Checkin is from the user');
      // console.log(props.auth.user.id);
      setMoreOptions([...moreOptions, ...[{
        content: 'Edit Checkin',
        value: 'edit',
        onClick: () => {
          const { checkin } = props;
          const { venue } = checkin;
          props.history.push({
            pathname: `/venues/${hashids.encode(venue.id)}`,
            search: `action=checkin&itemId=${venue.id}&editId=${checkin.id}`,
            state: { checkin },
          });
        },
      }, {
        content: 'Delete Checkin',
        value: 'delete',
        style: {
          color: '#ef5164',
        },
        onClick: () => {
          ModalDialog.show({
            title: 'Delete Checkin',
            message: 'Are you sure you want to delete this checkin?',
            buttons: [{
              label: 'Cancel',
              onClick: () => {
                ModalDialog.close();
              },
            }, {
              label: 'Delete',
              style: { color: 'rgb(239, 81, 100)' },
              color: 'danger',
              onClick: () => {
                props.deleteCheckin(props.checkin);
                ModalDialog.close();
              },
            }],
            onClose: () => {
              ModalDialog.close();
            },
          });
        },
      }]]);
    } else {
      setMoreOptions([...moreOptions, {
        content: 'Report Post',
        value: 'report',
        onClick: () => {
          console.log('TODO Send email to our admins with the details?');
        },
      }]);
    }
  }, []);

  useEffect(() => {
    if (props.checkin) {
      const { venue } = props.checkin;
      if (venue) {
        window.analytics?.track('Venue Impressions', {
          venue_id: venue.id,
          user_id: props.auth && props.auth.user && props.auth.user.id,
          impression_type: 'user-generated',
        });
      }
    }
  }, [props.checkin && props.checkin.venue]);

  const getImage = () => {
    const { checkin } = props;
    let imageUrl = Placeholder.venue;

    if (checkin.image_url) {
      imageUrl = checkin.image_url;
    } else if (checkin.image_url) {
      imageUrl = checkin.image_url;
    }

    return imageUrl;
  };

  const showVenueDetail = (venue) => {
    if (venue && venue.id) {
      window.analytics?.track('Venue Clicks', {
        venue_id: venue.id,
        user_id: props.auth && props.auth.user && props.auth.user.id,
      });
      props.history.push({ pathname: `/venues/${hashids.encode(venue.id)}`, state: { venue } });
    }
  };

  const userName = () => {
    const { checkin } = props;
    if (checkin.user) {
      if (checkin.user.alias) {
        return checkin.user.alias;
      }
      if (checkin.user.full_name) {
        return checkin.user.full_name;
      }
      return `${checkin.user.first_name} ${checkin.user.last_name}`;
    }
    return '';
  };

  const renderHeader = () => {
    const { checkin } = props;
    return (
      <div style={{ display: 'flex', padding: 5 }} className="feed-checkin-card-header">
        <Link to={checkin.user ? `/users/${hashids.encode(checkin.user.id)}` : null}>
          <Avatar src={checkin.user ? checkin.user.image_url : null} style={{ height: 28, width: 28, margin: '8px 12px' }} alt={checkin.user ? checkin.user.display_name : 'Boxpressd User'}>{checkin.user && checkin.user.first_name.charAt(0)}</Avatar>
        </Link>
        <div
          style={{ flex: 1 }}
          itemProp="author"
          itemScope
          itemType="https://schema.org/Person"
        >
          <Link to={checkin.user ? `/users/${hashids.encode(checkin.user.id)}` : null}>
            <div style={{ fontWeight: 600, color: '#2b2b2b !important' }} itemProp="name">{userName()}</div>
          </Link>
          <div style={{ fontSize: 12 }}>
            {`Checked In ${renderTimestamp(checkin.timestamp)}`}
          </div>
        </div>
        <IconButton
          edge="end"
          color="inherit"
          aria-label="More Options"
          style={{ marginRight: 5 }}
          onClick={() => {
            BottomSheet.show({
              items: moreOptions,
            });
          }}
        >
          <Icon name="more-horizontal" style={{ height: 22, width: 22 }} />
        </IconButton>
      </div>
    );
  };

  const renderMedia = () => (
    <div className="image">
      { renderHeader() }
      { renderInnerMedia() }
    </div>
  );

  const renderInnerMedia = () => {
    const { checkin } = props;
    if (checkin.media && checkin.media.length > 0) {
      if (checkin.media[0].media_type === 'video') {
        return (
          <VideoSource
            videoSrc={checkin.media[0].media_url}
            width="350"
            height="233"
            placeholder={checkin.media[0].thumbnail_url}
          />
        );
      }
      return (
        <MediaGrid
          media={checkin.media.map((media) => ({ type: media.media_type, src: media.media_url }))}
          lazyImages={checkin.media.map((media) => ({ type: media.media_type, src: Resize.size(media.media_url, { height: 100, width: 100, croptType: 'crop' }) }))}
          thumbnailMedia={checkin.media.map((media) => ({ type: media.media_type, src: Resize.size(media.media_url) }))}
          onClick={() => showVenueDetail(checkin.venue)}
        />
      );
    }
    // if (checkin.video_url) {
    //   return (
    //     <VideoSource
    //       videoSrc={checkin.video_url}
    //       width="350"
    //       height="233"
    //       // placeholder={checkin.video_url} // FIXME First frame from video
    //       // title={post.title}
    //     />
    //   );
    // }
    return (
      <Link to={checkin.venue ? `/venues/${hashids.encode(checkin.venue.id)}` : null}>
        <div className="img" style={{ backgroundImage: `url("${getImage()}"), url("${Placeholder.venue}")` }} />
      </Link>
    );
  };

  const renderAverageRating = () => {
    const { checkin, avgRating, totalRatings } = props;
    let rating = avgRating;
    if (typeof rating === 'boolean') {
      rating = checkin.venue.avg_rating;
    }
    return (
      <div>
        <span>Average rating</span>
        <Rating
          initialRating={rating / 20}
          emptySymbol="far fa-star"
          fullSymbol="fas fa-star"
          fractions={2}
          readonly
          style={{ color: 'rgb(214, 194, 144)', marginBottom: 10, marginLeft: 10 }}
        />
        <span style={{ marginLeft: 10 }}>
          {totalRatings || (checkin.venue && checkin.venue.total_ratings) || 0}
          {' '}
          ratings
        </span>
      </div>
    );
  };

  const renderRating = () => {
    const { checkin } = props;
    const advancedRating = checkin.advance_rating;
    return (
      <div>
        <div style={{ display: 'none' }} itemProp="reviewRating" itemScope itemType="https://schema.org/Rating">
          <span itemProp="ratingValue">{advancedRating.rating}</span>
        </div>
        <Row>
          <Col>
            <div>{messages[language]?.selection || 'Selection'}</div>
            <div>{messages[language]?.service || 'Service'}</div>
            <div>{messages[language]?.atmosphere || 'Atmosphere'}</div>
            <div>{messages[language]?.price || 'Price'}</div>
          </Col>
          <Col style={{ textAlign: 'right' }}>
            <div>
              <Rating
                initialRating={advancedRating.selection || advancedRating.rating}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)' }}
              />
            </div>
            <div>
              <Rating
                initialRating={advancedRating.service || advancedRating.rating}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)' }}
              />
            </div>
            <div>
              <Rating
                initialRating={advancedRating.atmosphere || advancedRating.rating}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)' }}
              />
            </div>
            <div>
              <Rating
                initialRating={advancedRating.price || advancedRating.rating}
                emptySymbol="far fa-star"
                fullSymbol="fas fa-star"
                fractions={2}
                readonly
                style={{ color: 'rgb(214, 194, 144)' }}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  const renderComment = () => (
    <div style={{ display: 'flex', marginTop: 20 }}>
      <Icon name={['fas', 'quote-left']} vendor="fa" style={{ color: '#d9d9d9', width: 24 }} />
      <div style={{ flex: 'auto', marginLeft: 6, marginBottom: 10 }} itemProp="reviewBody">
        {props.checkin.comment}
      </div>
    </div>
  );

  const { checkin, showTitle } = props;
  const hasMedia = checkin.image_url || checkin.video_url || checkin.image_url || (checkin.media && checkin.media.length > 0);
  return (
    <div className={`feed-card-item ${props.paddedBottom ? 'card-padded-bottom' : ''}`} itemProp="review" itemScope itemType="https://schema.org/Review">
      { (hasMedia) && renderMedia() }
      <div className="content">
        { !hasMedia && renderHeader() }
        <div className="content-right" style={{ width: '100%', minHeight: isMobile ? 285 : 230, paddingTop: 1 }}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ display: 'none' }}
              itemType="https://schema.org/LocalBusiness"
              itemProp="itemReviewed"
              itemScope
            >
              <span itemProp="name">{checkin.venue && checkin.venue.name}</span>
            </div>
            {showTitle && Venue.getImage(checkin.venue) && (
              <Avatar
                src={Venue.getImage(checkin.venue)}
                alt={checkin.venue && checkin.venue.name}
                style={{
                  height: 60,
                  width: 60,
                  margin: 10,
                }}
              />
            )}
            <span onClick={() => showVenueDetail(checkin.venue)}>
              <h3 className="link">{showTitle && checkin.venue && checkin.venue.name }</h3>
              <div style={{ marginBottom: 20 }}>{showTitle && checkin.venue && (checkin.venue.location.formatted_address || checkin.venue.website) }</div>
            </span>
          </div>
          <div>
            { props.avgRating && renderAverageRating() }
            {/* { isMobile && showTitle && renderActionIcons() } */}
          </div>
          { checkin.advance_rating && renderRating() }
          { checkin.comment && renderComment() }
        </div>
        <FeedCardActions
          postId={checkin.id}
          commentType="checkin"
          commentRoute="checkins"
          commentCount={props.commentCount}
          likeCount={props.likeCount}
          commentOnClick={props.compact ? () => {
            // FIXME Just pass it no matter what?
            if (typeof props.onCommentClick === 'function') {
              props.onCommentClick();
            }
          } : null}
          onLikedToggle={(liked) => {
            console.log(`The post is ${liked ? 'liked' : 'not liked'}`);
          }}
          onPostComment={(comment, parentId) => {
            console.log('Comment posted...');
            console.log(comment);
            axios.post(`${config.apiEndPoint}/venues/checkins/${checkin.id}/comments`, {
              checkin_id: checkin.id,
              // FIXME We don't want any HTML past this point - convert to plain text - use something better than this approach?
              comment: comment.replace('<br>', ''),
              user_id: props.auth.user.id,
              parent_id: parentId,
              comment_timestamp: new Date().toISOString(),
            });
          }}
          onShare={() => ShareIntent.share(new ShareItem({
            title: props.checkin.venue.name,
            text: `View my latest checkin at ${props.checkin.venue.name} on Boxpressd`,
            path: 'venue',
            route: `/venues/${hashids.encode(props.checkin.venue.id)}?checkinId=${hashids.encode(props.checkin.id)}`,
            image: checkinImage(),
          }))}
        />
      </div>
    </div>
  );
}

FeedCheckinCard.propTypes = {
  checkin: PropTypes.object.isRequired,
  showTitle: PropTypes.bool,
  avgRating: PropTypes.bool || PropTypes.number,
  totalRatings: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteCheckin: (checkin) => dispatch(deleteCheckinAction(checkin)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FeedCheckinCard));
