import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import './style.scss';
import { withRouter } from 'react-router-dom';
import { isMobileOnly } from 'mobile-device-detect';
import { connect } from 'react-redux';
import { Card, Container, Row } from 'reactstrap';
import Hashids from 'hashids/cjs';
import { renderTimestamp } from '../../utils/formatting';
import { addToast as actionAddToast } from '../../actions';
import Carousel from '../carousel';
import FeedCardActions from '../FeedCardActions';
import ShareIntent from '../../utils/shareUtils';
import ShareItem from '../../models/ShareItem';
import RichCard from '../RichCard';
import VideoSource from '../VideoSource';
import axios from 'axios';
import { config } from '../../settings';
import { Resize } from '../../utils/imageUtils';
import MediaGrid from '../media-grid';

const hashids = new Hashids('', 12);

export const getProfileImage = (image) => {
  if (image) {
    if (image.indexOf('http') === -1) {
      return `https://${image}`;
    }
    return image;
  }
  return null;
};

const generateFeatureImage = (post) => {
  console.debug(post);
  if (post && post.media && post.media.length === 1) {
    if (post.media[0].media_type === 'image') {
      console.debug('Single image: ' + post.media[0].media_url);
      return post.media[0].media_url;
    }
    if (post.media[0].media_type === 'video') {
      console.debug('Single video: ' + post.media[0].media_url.replace('.mp4', '.jpg'));
      return post.media[0].media_url.replace('.mp4', '.jpg');
    }
  }
  if (post && post.media && post.media.length > 1) {
    // TODO Generate the media grid with transparent bg (as png) as base64? Or upload it somewhere first?
  }
  return null;
};

// FIXME All of these cards should extend from a top level one that handles all the common things like saving items
//  Or just rename this to something generic - the only difference is probably who created it (a brand, venue, or group)
//  so we can include title, subtitle, avatar, and link instead
class BusinessPostCardList extends PureComponent {
  constructor(props) {
    super(props);

    const { auth, post } = this.props;
    const { venue, brand } = post;
    const item = venue || brand;

    if (item) {
      if (window.analytics) {
        window.analytics.track('Business Post Impressions', {
          post_id: post.id,
          business_id: item && item.id,
          business_type: post.business_type,
          user_id: auth && auth.user && auth.user.id,
        });

        if (venue) {
          window.analytics.track('Venue Impressions', {
            venue_id: venue.id,
            user_id: auth && auth.user && auth.user.id,
            impression_type: 'user-generated',
          });
        }

        if (brand) {
          window.analytics.track('Brand Impressions', {
            brand_id: brand.id,
            user_id: auth && auth.user && auth.user.id,
            impression_type: 'user-generated',
          });
        }
      }
    }
  }

  // moreOptions = [{
  //   content: 'Save for later',
  //   icon: 'bookmark',
  //   value: 'save',
  //   onClick: () => {
  //     const { post } = this.props;
  //     const { auth } = this.props;
  //     const { user } = auth;
  //     saveItem(user, {
  //       singular: 'venue_post',
  //       plural: 'venue_posts',
  //     }, post);
  //     this.props.addToast({
  //       content: (<>
  //         {'Saved post'}
  //       </>),
  //       duration: 6000,
  //     });
  //   },
  // }];

  getFeaturedImage = () => {
    const { post } = this.props;
    let imageURL = null; // TODO Default image
    if (post.image) {
      imageURL = post.image;
    } else if (post.featured_image) {
      imageURL = post.featured_image.url;
    } else if (post.images && post.images.length) {
      imageURL = post.images[0];
    }
    // FIXME For now, this isn't working as expected - seems like an issue with resizing
    // if (typeof config.CDNUrl !== 'undefined' && config.CDNUrl !== '') {
    //   imageURL = imageURL.replace(config.wpUrl, config.CDNUrl);
    // }

    return imageURL;
  };

  // toggleSavedItem = (save) => {
  //   let { post, auth } = this.props;
  //   const { user } = auth;
  //   post = JSON.parse(JSON.stringify(post));
  //   console.log('Toggling saved post for state:');
  //   if (save) {
  //     console.log('save');
  //     saveItem(user, 'business_post' }, post);
  //   } else {
  //     console.log('unsave');
  //     unsaveItem(user, {
  //       item_id: post.id,
  //       type: 'business_post',
  //     });
  //   }
  // };

  onNavigate = (url) => {
    const { auth, post } = this.props;
    const { venue, brand } = post;
    const item = venue || brand || {};
    if (item) {
      if (window.analytics) {
        window.analytics.track('Business Post Clicks', {
          post_id: post.id,
          business_id: item.id,
          business_type: post.business_type,
          user_id: auth && auth.user && auth.user.id,
          url,
        });
      }
    }
  };

  renderHeader = () => {
    const { auth, post } = this.props;
    const { venue, brand } = post;
    const item = venue || brand || {};
    const date = post.timestamp;
    return (
      <div
        style={{
          display: 'flex',
          padding: 5,
        }}
        onClick={() => {
          if (venue) {
            window.analytics?.track('Venue Clicks', {
              venue_id: venue.id,
              user_id: auth && auth.user && auth.user.id,
              impression_type: 'user-generated',
            });
          }

          if (brand) {
            window.analytics?.track('Brand Clicks', {
              brand_id: brand.id,
              user_id: auth && auth.user && auth.user.id,
              impression_type: 'user-generated',
            });
          }
          this.props.history.push(`/${post.business_type}s/${hashids.encode(post[post.business_type].id)}`);
        }}
      >
        <Avatar
          src={item && (getProfileImage(item.profile_image_url) || getProfileImage(item.logo_image_url))}
          alt={(item && item.name) || 'Boxpressd Business'}
          style={{
            height: 28,
            width: 28,
            margin: '8px 12px',
            cursor: 'pointer',
          }}
        >
          {item && item.name && item.name.charAt(0)}
        </Avatar>
        <div style={{ flex: 1 }}>
          {/* FIXME Allow this card to show "Sponsored" when we're showing it as an ad preview - add prop for `sponsored` */}
          <div
            style={{
              fontWeight: 600,
              color: '#2b2b2b !important',
              cursor: 'pointer',
            }}
          >
            {item.name}
          </div>
          <div style={{ fontSize: 12 }}>
            {`Posted ${renderTimestamp(date)}`}
          </div>
        </div>
      </div>
    );
  };

  renderInnerMedia = () => {
    const { auth, post } = this.props;
    if (post.cards && post.cards.length) {
      return (
        <Carousel slidesToShow={isMobileOnly ? 1.5 : 2.2} slidesToScroll={1} style={{ width: '100%' }}>
          {[...post.cards].sort((a, b) => a.id - b.id).map(({ headline, description, media_url, media_type, url }) => (
            <div
              style={{ cursor: 'pointer' }}
              onClick={(e) => {
                e.preventDefault();
                if (url) {
                  // FIXME Should we allow them to be added as the path? Or just link to https://boxpressd.app and throw
                  //  that part out if it's included in order to determine to route? Maybe make a helper function
                  //  to handle whether or not to route with history or push a new URL - I believe this is used in
                  //  other parts of the app like for routing to articles
                  const { venue, brand } = post;
                  const item = venue || brand || {};
                  if (item && window.analytics) {
                    window.analytics.track('Business Post Clicks', {
                      post_id: post.id,
                      business_id: item.id,
                      business_type: post.business_type,
                      user_id: auth && auth.user && auth.user.id,
                      url,
                    });
                  }
                  if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
                    this.props.history.push(url);
                  } else {
                    window.location.href = url;
                  }
                }
              }}
            >
              <Card
                style={{
                  // width: 250,
                  margin: 4,
                  cursor: 'pointer',
                }}
              >
                {(media_type === 'video' || (media_url && media_url.indexOf('.mp4') !== -1)) && (
                  <div style={{ height: 250 }}>
                    <VideoSource
                      videoSrc={media_url}
                      scaleWidth="100%"
                      scaleHeight="100%"
                      // placeholder={mediaUrl.replace('.mp4', '.jpg')}
                      title={post.title}
                      // thumbnailTime={video_timestamp_start || 0.1}
                    />
                  </div>
                )}
                {media_type === 'image' && (
                  <img
                    src={media_url || 'https://cdn.boxpressd.io/placeholder/500x500/no_cigar.png'}
                    style={{
                      height: 250,
                      objectFit: 'cover',
                    }}
                  />
                )}
                <div style={{ margin: 16 }}>
                  <h4
                    style={{
                      marginTop: 6,
                      marginBottom: 4,
                    }}
                  >
                    {headline}
                  </h4>
                  <RichCard
                    compact={(post.image && post.image.length > 0) || (post.images && post.images.length > 0)}
                    hideMeta={post.media && post.media.length > 0}
                    onNavigate={this.onNavigate}
                  >
                    {description || ''}
                  </RichCard>
                </div>
              </Card>
            </div>
          ))}
        </Carousel>
      );
    }
    // FIXME I'm not going to allow more than 1 image/video right now
    if (post.media && post.media.length) {
      if (post.media[0].media_type === 'video' || post.media[0].media_url.indexOf('.mp4') !== -1) {
        return (
          <div style={{ margin: 10 }}>
            <VideoSource
              videoSrc={post.media[0].media_url}
              scaleWidth="100%"
              scaleHeight="100%"
              // placeholder={post.image}
              title={post.title}
              thumbnailTime={post.media[0].video_timestamp_start || 0.1}
            />
          </div>
        );
      }
      return (
        <Container>
          <Row style={{ margin: '10px 0' }}>
            {post.media.length > 1 && (
              <MediaGrid
                media={post.media.map((media) => ({
                  type: media.media_type,
                  src: media.media_url,
                }))}
                direction="vertical"
                // direction={props.compact ? 'horizontal' : 'vertical'}
                lazyImages={post.media.map((media) => ({
                  type: media.media_type,
                  src: media.media_type !== 'video' ? Resize.size(media.media_url, { height: 100, width: 100, cropType: 'crop' }) : media.media_url,
                }))}
                thumbnailMedia={post.media.map((media) => ({
                  type: media.media_type,
                  src: media.media_type !== 'video' ? Resize.size(media.media_url) : media.media_url,
                }))}
                onImageClick={() => {
                  // TODO?
                }}
              />
            )}
            {post.media.length === 1 && (
              <img src={post.media[0].media_url} style={{ width: '100%', maxHeight: 600, objectFit: 'cover', borderRadius: 6 }} />
            )}
          </Row>
        </Container>
      );
    }
    return null;
  };

  renderMedia = () => (
    <div className="image">
      {this.renderHeader()}
      {this.renderInnerMedia()}
    </div>
  );

  render() {
    const { post } = this.props;
    // console.log('Got group post');
    // console.log(post);

    return (
      <div className="feed-card-item">
        {this.renderMedia()}
        <div className="content" style={{ marginBottom: 0 }}>
          <div
            className="content-right"
            style={{
              width: '100%',
              paddingTop: 1,
            }}
          >
            <RichCard
              compact={false}
              hideMeta={post.media && post.media.length > 0}
              onNavigate={this.onNavigate}
              meta={post.meta && {
                ogTitle: post.meta.title,
                ogDescription: post.meta.description,
                ogUrl: post.meta.domain,
                requestUrl: post.meta.url,
                ogImage: {
                  url: post.meta.image_url,
                },
                ogVideo: {
                  url: post.meta.video_url,
                },
              }}
            >
              {post.content || ''}
            </RichCard>
          </div>
          <FeedCardActions
            postId={post.id}
            commentType="business_post"
            commentRoute="posts"
            commentCount={this.props.commentCount}
            likeCount={this.props.likeCount}
            commentOnClick={this.props.compact ? () => {
              // FIXME Just pass it no matter what?
              if (typeof this.props.onCommentClick === 'function') {
                this.props.onCommentClick();
              }
            } : null}
            onLikedToggle={(liked) => {
              console.log(`The post is ${liked ? 'liked' : 'not liked'}`);
            }}
            onPostComment={(comment, parentId) => {
              console.log('Comment posted...');
              console.log(comment);
              axios.post(`${config.apiEndPoint}/businesses/posts/${post.id}/comments`, {
                business_post_id: post.id,
                // FIXME We don't want any HTML past this point - convert to plain text - use something better than this approach?
                comment: comment.replace('<br>', ''),
                user_id: this.props.auth.user.id,
                parent_id: parentId,
                comment_timestamp: new Date().toISOString(),
              });
            }}
            onShare={() => {
              ShareIntent.share(new ShareItem({
                title: post[post.business_type].name,
                text: `View this post from ${post[post.business_type].name} on Boxpressd`,
                path: post.business_type,
                route: `posts/${hashids.encode(post.id)}/comments`,
                // route: `/${post.business_type}s/${hashids.encode(post[post.business_type].id)}?postId=${hashids.encode(post.id)}`,
                image: generateFeatureImage(post),
              }));
            }}
          />
        </div>
      </div>
    );
  }
}

BusinessPostCardList.propTypes = {
  post: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addToast: (data) => dispatch(actionAddToast(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessPostCardList));
